<template>
    <icon-base :width="size + 'px'" :fill="color" :viewBox="'0 0 200 200'">
        <path d="M162,83h-8V54.8c0-29.8-24.2-54-54-54S46,25,46,54.8V83h-8c-6.3,0-11.4,5.1-11.4,11.4v7v79.4v7  c0,6.3,5.1,11.4,11.4,11.4h62h62c6.3,0,11.4-5.1,11.4-11.4v-7v-79.4v-7C173.4,88.1,168.3,83,162,83z M112.2,164.9H87.8l4.4-20.8  c-4.1-2.6-6.8-7.1-6.8-12.3c0-8.1,6.5-14.6,14.6-14.6s14.6,6.5,14.6,14.6c0,5.2-2.7,9.7-6.8,12.3L112.2,164.9z M134.7,83H100H65.3  V54.8c0-19.2,15.6-34.7,34.7-34.7c19.2,0,34.7,15.6,34.7,34.7V83z"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconLock',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
